export interface Params {
  [param: string]: any
}

export type BillingProvider = 'STRIPE' | 'SHOPIFY'
export type PlanTier = 'NO_PLAN' | 'SENSORS_ONLY' | 'STARTER' | 'GROWTH' | 'PRO' | 'ENTERPRISE'

export interface UsageLimits {
  // for numbers, null == no limit
  distribution_centers: number | null
  packaging_configs: number | null
  api_access: boolean
  simulation_lab: boolean
  recommendations_weekly: number | null
  integrations: boolean
}

export interface Organization {
  uuid: string
  name: string
  bulk_recommendation_enabled: boolean
  plan_tier: PlanTier
  sensor_discount_percent: number
  billing_provider: BillingProvider
  usage_limits: UsageLimits
  current_week_recommendation_count: number
}

export interface User {
  email: string
  first_name: string
  uuid: string
  organization: Organization
}

export interface ShipmentEvent {
  time: string
  location_message: string
  location_name: string
}

export interface TempReading {
  time: string
  ambient_temp_f?: number
  internal_temp_f?: number
  hours_in_transit?: number
  events: ShipmentEvent[]
}

export interface TempTime {
  time: string
  temp_f: number
}

export interface LabelTime {
  time: string
  label: string
}

export interface ActualPerformanceData {
  actual_ambient_temp_f?: TempTime[]
  sensor_temp_f?: TempTime[]
}

export interface SimulatedPerformanceData {
  simulated_product_temp_f?: TempTime[]
  simulated_product_surface_temp_f?: TempTime[]
  simulated_product_core_temp_f?: TempTime[]
  simulated_coolant_temp_f?: TempTime[]
  simulated_coolant_surface_temp_f?: TempTime[]
  simulated_coolant_core_temp_f?: TempTime[]
  simulation_error_message?: string
}

export interface PredictedPerformanceData {
  predicted_ambient_temp_f?: TempTime[]
  predicted_product_temp_f?: TempTime[]
  predicted_product_surface_temp_f?: TempTime[]
  predicted_product_core_temp_f?: TempTime[]
  predicted_coolant_temp_f?: TempTime[]
  predicted_coolant_surface_temp_f?: TempTime[]
  predicted_coolant_core_temp_f?: TempTime[]
}

// Ideally, this interface is generated on the above types, but kea typegen doesn't like that.
export interface TimeSeriesData {
  actual_ambient_temp_f?: TempTime[]
  sensor_temp_f?: TempTime[]
  simulated_product_temp_f?: TempTime[]
  simulated_product_surface_temp_f?: TempTime[]
  simulated_product_core_temp_f?: TempTime[]
  simulated_coolant_surface_temp_f?: TempTime[]
  simulated_coolant_core_temp_f?: TempTime[]
  simulated_coolant_temp_f?: TempTime[]
  predicted_ambient_temp_f?: TempTime[]
  predicted_product_temp_f?: TempTime[]
  predicted_product_surface_temp_f?: TempTime[]
  predicted_product_core_temp_f?: TempTime[]
  predicted_coolant_surface_temp_f?: TempTime[]
  predicted_coolant_core_temp_f?: TempTime[]
  predicted_coolant_temp_f?: TempTime[]
}

export type TemperatureToolTipData = {
  [K in keyof TimeSeriesData]?: number
} & {
  time?: number
}

export interface RouteData {
  lat?: number
  location_message?: string
  location_name?: string
  lon?: number
  time: string
}

export interface PredictedTempReading {
  time: string
  predicted_ambient_temp_f?: number
}

export interface CombinedTempReading {
  time: number
  ambientTempF?: number
  sensorTempF?: number
  predictedAmbientTempF?: number
  location?: string
}

export interface Item {
  sku: string
  name: string
  quantity: number
  weight_lbs: number
}

export interface ShipmentPrediction {
  uuid: string
  created_at: string
  carrier?: string
  carrier_service?: string
  estimated_shipped_at?: string
  carrier_deliver_by?: string
  carrier_transit_days?: number
  heat_exposure?: number
  coolant_rule?: CoolantRule
  synced_with_source_integration_at?: string
  error_message?: string
  is_synced?: boolean
  expected_days_to_delivery?: number
  estimated_delivered_at?: string
  predicted_temp_readings?: PredictedTempReading[]
  coolant_block_type?: CoolantType
  coolant_block_count?: number
  coolant_block_weight_lbs?: number
  warnings?: string[]
}

export interface Shipment {
  rowNumber?: number
  uuid: string
  tracking_number: string
  external_shipment_id?: string
  order_number?: string
  delivered_at?: string
  shipped_at?: string
  max_ambient_temp_f: number
  temp_at_delivery_f?: number
  hours_in_transit?: number
  total_weight_lbs: number
  end_state: string
  end_zip: string
  start_state: string
  temp_readings?: TempReading[]
  actual_performance?: ActualPerformanceData
  simulated_performance?: SimulatedPerformanceData
  predicted_performance?: PredictedPerformanceData
  scans?: LabelTime[]
  hours_over_threshold?: number
  packaging_config_name?: string
  distribution_center_name?: string
  carrier?: string
  carrier_service?: string
  insulation_name?: string
  shipment_status: string
  sensor_status: string
  start_zip: string
  historical_heat_exposure?: number
  raw_tracking_data_error_message?: string
  carrier_transit_days?: number
  hours_to_failure?: number
  heat_exposure_of_failure?: number
  latest_shipment_prediction?: ShipmentPrediction
  shipment_prediction_creation_error_message?: string
  order_created_at?: string
  tags?: string[]
  items?: Item[]
  is_integration_source_data_locked?: boolean
  expected_ship_date?: string
  expected_ship_time?: string
  distribution_center_id?: string
  packaging_config_id?: string
  shipping_method_id?: string
  total_product_weight_override_lbs?: number
  shipped_at_warning?: string
  tracking_number_warning?: string
  simulated_hours_to_failure?: number
  is_sensor_data_outlier?: boolean
  coolant_block_type?: CoolantType
  coolant_block_count?: number
  coolant_block_weight_lbs?: number
}

export interface SensorScan {
  uuid: string
  scanned_at: string
  shipment: null | {
    uuid: string
    tracking_number: string
    order_number: string
    created_at: string
    start_state: string
    end_state: string
    carrier: string
    shipment_status: string
    shipped_at: string | null
    delivered_at: string | null
    carrier_service: string
    hours_in_transit: number
    packaging_config_id: string | null
    distribution_center_id: string | null
    shipping_method_id: string | null
    max_ambient_temp_f: number
    avg_ambient_temp_f: number
    temp_at_delivery_f: number
  }
}

export enum CoolantType {
  DryIce = 'DRY_ICE',
  GelPacks = 'GEL_PACKS',
}

export enum InsulationType {
  StarchBased = 'STARCH_BASED',
  Polystyrene = 'POLYSTYRENE',
  CottonBased = 'COTTON_BASED',
  ReflectiveFoil = 'REFLECTIVE_FOIL',
  PETBased = 'PET_BASED',
  PaperBased = 'PAPER_BASED',
  Other = 'OTHER',
}

export interface PackagingConfig {
  uuid: string
  created_at: string
  name: string
  box_width_in: number
  box_height_in: number
  box_length_in: number
  insulation_type: InsulationType
  insulation_thickness_in: number
  insulation_r_value: number
  coolant_rules?: CoolantRule[]
  coolant_block: string
  coolant_recommendation_strategy: CoolantRecommendationStrategy | null
  coolant_type: CoolantType | null
  coolant_block_weight_lbs: number | null
  min_coolant_block_count: number | null
  max_coolant_block_count: number | null
  default_product_weight_lbs: number | null
  coolant_weight_discount_factor: number | null
  insulation_r_value_discount_factor: number | null
  thermal_conductivity_multiplier: number | null
  priority: number
  max_weight_lbs: number
  active: boolean
}

export interface CoolantBlock {
  uuid: string
  created_at: string
  name: string
  coolant_type: CoolantType
  length_in: number
  width_in: number
  height_in: number
  weight_lbs: number
}

export interface Product {
  uuid: string
  name: string
  sku: string
  weight_lbs: number | null
  width_in: number | null
  height_in: number | null
  length_in: number | null
}

export enum CoolantRecommendationStrategy {
  RULE_BASED = 'RULE_BASED',
  SIMULATION_BASED = 'SIMULATION_BASED',
}

export enum SimulationThresholdField {
  Product = 'PRODUCT',
  ProductCore = 'PRODUCT_CORE',
  ProductSurface = 'PRODUCT_SURFACE',
  Coolant = 'COOLANT',
  CoolantCore = 'COOLANT_CORE',
  CoolantSurface = 'COOLANT_SURFACE',
}

export interface Insulation {
  uuid: string
  created_at: string
  name: string
  insulation_type: InsulationType
  thickness_in: number
  r_value: number
}

export interface OrganizationSettings {
  default_product_temp_threshold_f: number
  default_initial_product_temp_f: number
  default_product_weight_lbs: number
  default_porch_hours: number
  order_cutoff_buffer_hours: number
  default_delivery_address_is_residential: boolean
  service_level_upgrade_policy: ShippingServiceLevelUpgradePolicy
}

export interface OrganizationApiKey {
  id: string
  created_at: string
  name: string
  api_key: string
}

export interface ShipmentTableFilter {
  field: string
  value?: string
  value_list?: string[]
  min?: number
  max?: number
  start_date?: Date
  end_date?: Date
  operator: 'in' | 'equals' | 'contains' | 'range' | 'date-range'
}

export type SortDirection = 'asc' | 'desc'

export interface ShipmentColumnSorting {
  column: string
  direction: SortDirection
}

export enum OMSProvider {
  ShipStation = 'SHIP_STATION',
  Shopify = 'SHOPIFY',
}

export interface DistributionCenter {
  uuid: string
  is_from_integration: boolean
  integration_name: string
  name: string
  street_address: string
  street_address_2: string
  city: string
  state: string
  zip_code: string
  estimated_pickup_time: string
  shipping_methods: string[]
  active: boolean
}

export interface CoolantDescription {
  coolant_type?: CoolantType
  coolant_lbs?: number
}

export interface CoolantBlocksDescription {
  coolant_block_type?: CoolantType
  coolant_block_count?: number
  coolant_block_weight_lbs?: number
}

export interface CoolantRule {
  coolant_blocks_description: CoolantBlocksDescription
  max_heat_exposure: number
  is_default?: boolean
}

export interface EditableCoolantRule {
  coolant_blocks_description: CoolantBlocksDescription
  max_heat_exposure?: number
  is_default?: boolean
}

export interface ServerErrorType {
  status?: number
  detail?: string
}

export interface SyncLogType {
  uuid: string
  created_at: string
  started_at: string
  completed_at: string
  status: string
  error_message: string
  count_shipments_created_or_updated: number
  count_shipment_predictions_created_or_updated: number
  count_updates_written_to_integration: number
}

export interface RecentIntegrationActivitySummary {
  count_shipments_created_or_updated: number
  count_recommendations_run: number
  count_errors: number
  count_pending: number
}

export enum ShipmentProcessingJobStatus {
  PENDING = 'PENDING',
  SHIPMENT_UPDATED = 'SHIPMENT_UPDATED',
  RECOMMENDATION_MADE = 'RECOMMENDATION_MADE',
  COMPLETED = 'COMPLETED',
  ERROR_UPDATING_SHIPMENT = 'ERROR_UPDATING_SHIPMENT',
  ERROR_MAKING_RECOMMENDATION = 'ERROR_MAKING_RECOMMENDATION',
}
export interface IntegrationErrorDetail {
  uuid: string
  created_at: string
  job_status: ShipmentProcessingJobStatus
  error: KICError
  order_number: string
  tracking_number: string
}
export interface RecentIntegrationActivity {
  last_hour_summary: RecentIntegrationActivitySummary
  last_24_hours_summary: RecentIntegrationActivitySummary
  recent_error_details: IntegrationErrorDetail[]
}

// From https://stackoverflow.com/questions/58434389/typescript-deep-keyof-of-a-nested-object
type DotPrefix<T extends string> = T extends '' ? '' : `.${T}`

export type DotNestedKeys<T> = (
  T extends object
    ? { [K in Exclude<keyof T, symbol>]: `${K}${DotPrefix<DotNestedKeys<T[K]>>}` }[Exclude<keyof T, symbol>]
    : ''
) extends infer D
  ? Extract<D, string>
  : never

export interface ShipmentIngestionFilter {
  field: string
  operator: 'equals' | 'in'
  value?: string
  value_list?: string[]
}

export interface IntegrationIngestionFilter {
  version: number
  filters: ShipmentIngestionFilter[]
}

export enum RecommendationPolicy {
  ALWAYS = 'ALWAYS',
  WHEN_BLANK = 'WHEN_BLANK',
  NEVER = 'NEVER',
}

export enum RecommendationTriggerPolicy {
  ON_SHIPMENT_CREATION = 'ON_SHIPMENT_CREATION',
  ON_SHIPMENT_CREATION_OR_UPDATE = 'ON_SHIPMENT_CREATION_OR_UPDATE',
  ON_EVERY_SYNC = 'ON_EVERY_SYNC',
  ON_PREDICTION_EXPIRATION_OR_SHIPMENT_CREATION_OR_UPDATE = 'ON_PREDICTION_EXPIRATION_OR_SHIPMENT_CREATION_OR_UPDATE',
  NO_SYNC = 'NO_SYNC',
}

export interface ShipmentRecommendationPolicies {
  distribution_center_selection?: RecommendationPolicy
  ship_date_recommendation?: RecommendationPolicy
  carrier_service_recommendation?: RecommendationPolicy
  packout_recommendation?: RecommendationPolicy
  coolant_recommendation?: RecommendationPolicy
}

export interface CoolantMapping {
  coolant_blocks_description: CoolantBlocksDescription
  external_value: string
}

export interface ShipmentBatchCounts {
  total_count: number
  pending_count: number
  completed_count: number
  error_count: number
}

export interface ShipmentBatch {
  uuid: string
  created_at: string
  external_id: string
  batch_status: string
  shipment_processing_job_counts: ShipmentBatchCounts
  coolant_recommendation_policy: RecommendationPolicy
  ship_date_recommendation_policy: RecommendationPolicy
}

export enum AmbientTempProfileType {
  MODERATE_DAY_NIGHT_CYCLE = 'moderate_day_night_cycle',
  SIGNIFICANT_DAY_NIGHT_CYCLE = 'significant_day_night_cycle',
  MINIMAL_DAY_NIGHT_CYCLE = 'minimal_day_night_cycle',
  COLD_TO_HOT_CLIMATE = 'cold_to_hot_climate',
  HOT_TO_COLD_CLIMATE = 'hot_to_cold_climate',
}

export enum DayOfWeek {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
}

export interface ShippingDay {
  is_enabled: boolean
  day_of_week?: DayOfWeek
}

export interface ShippingMethod {
  uuid: string
  name: string
  distribution_center: string
  service_level: ShippingServiceLevel
  carrier_service: string
  pickup_hour: number
  priority: number
  shipping_days: Record<DayOfWeek, ShippingDay>
  destination_zip_code_pattern_list: string
  has_line_haul: boolean
  line_haul_destination_zip_code: string | null
  is_line_haul_refrigerated: boolean
  line_haul_expected_transit_hours: number | null
  line_haul_destination_state: string | null
  active: boolean
}

export interface BulkRecommendationConfig {
  uuid: string
  created_at: string
  distribution_center: string
  packaging_config: string
  shipping_method: string
  zip_list: string
}

export enum ShipmentBatchStatus {
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  TIMED_OUT = 'TIMED_OUT',
}

export interface BulkRecommendationResult {
  ship_date: string
  latest_result_status?: ShipmentBatchStatus
  latest_completed_result_csv_url?: string
  latest_completed_result_created_at?: string
  latest_completed_result_recommendation_count?: number
}

export interface WrappedResponseData<T> {
  data: T
}

export interface LogisticsPlan {
  distribution_center_id: string
  shipping_method_id: string
  packaging_config_id: string
  coolant: CoolantBlocksDescription
  ship_date: string
}

export enum KICWarning {
  TRANSIT_TIME_LONGER_DUE_TO_WEEKEND_OR_HOLIDAY = 'TRANSIT_TIME_LONGER_DUE_TO_WEEKEND_OR_HOLIDAY',
  SHIPPING_SERVICE_LEVEL_UPGRADED = 'SHIPPING_SERVICE_LEVEL_UPGRADED',
  TEMP_THRESHOLD_EXCEEDED = 'TEMP_THRESHOLD_EXCEEDED',
  BOX_WEIGHT_LIMIT_EXCEEDED = 'BOX_WEIGHT_LIMIT_EXCEEDED',
  ITEM_MISSING_WEIGHT_SO_FALLING_BACK_TO_DEFAULT = 'ITEM_MISSING_WEIGHT_SO_FALLING_BACK_TO_DEFAULT',
}

export enum KICError {
  NO_SHIPPING_SERVICE_LEVEL_OPTIONS = 'NO_SHIPPING_SERVICE_LEVEL_OPTIONS',
  NO_SHIP_DATE_OPTIONS = 'NO_SHIP_DATE_OPTIONS',
  NO_SHIPPING_METHOD_OPTIONS = 'NO_SHIPPING_METHOD_OPTIONS',
  NO_PACKAGING_CONFIG_OPTIONS = 'NO_PACKAGING_CONFIG_OPTIONS',
  NO_COOLANT_OPTIONS = 'NO_COOLANT_OPTIONS',
  WEATHER_FORECAST_IS_ONLY_AVAILABLE_14_DAYS_INTO_FUTURE = 'WEATHER_FORECAST_IS_ONLY_AVAILABLE_14_DAYS_INTO_FUTURE',
  DISTRIBUTION_CENTER_DATA_MAPPING_MISSING = 'DISTRIBUTION_CENTER_DATA_MAPPING_MISSING',
  SHIPPING_METHOD_DATA_MAPPING_MISSING = 'SHIPPING_METHOD_DATA_MAPPING_MISSING',
  PACKAGING_CONFIG_DATA_MAPPING_MISSING = 'PACKAGING_CONFIG_DATA_MAPPING_MISSING',
  SHIPPING_SERVICE_LEVEL_DATA_MAPPING_MISSING = 'SHIPPING_SERVICE_LEVEL_DATA_MAPPING_MISSING',
  CARRIER_SERVICE_DATA_MAPPING_MISSING = 'CARRIER_SERVICE_DATA_MAPPING_MISSING',
  CARRIER_DATA_MAPPING_MISSING = 'CARRIER_DATA_MAPPING_MISSING',
  DESTINATION_ADDRESS_NOT_VALID = 'DESTINATION_ADDRESS_NOT_VALID',
}

export interface SimulationResult {
  ambient_temp_profile: TempTime[]
  product_temp_profile: TempTime[]
  product_surface_temp_profile: TempTime[]
  product_core_temp_profile: TempTime[]
  coolant_temp_profile: TempTime[]
  coolant_surface_temp_profile: TempTime[]
  coolant_core_temp_profile: TempTime[]
}

export interface ShipmentRecommendation {
  uuid: string
  logistics_plan: LogisticsPlan
  warnings: KICWarning[]
  items_in_shipment: Item[]
  transit_times: {
    expected_pickup_time: string
    expected_delivery_time: string
    line_haul_until: string
    porch_hours: number
  }
  simulation_result: SimulationResult
}

export enum ShippingServiceLevel {
  STANDARD = 'STANDARD',
  EXPEDITED = 'EXPEDITED',
  OVERNIGHT = 'OVERNIGHT',
}

export interface Dimensions {
  width_in: number
  height_in: number
  length_in: number
}

export interface OrderRecommendation {
  shipment_recommendations?: ShipmentRecommendation[]
}

export enum ShippingServiceLevelUpgradePolicy {
  NO_UPGRADES = 'NO_UPGRADES',
  ALLOW_SINGLE_LEVEL_UPGRADES = 'ALLOW_SINGLE_LEVEL_UPGRADES',
  ALLOW_UPGRADES = 'ALLOW_UPGRADES',
}

export enum ShopifyLogisticsPlanFieldOption {
  SHOPIFY_ORDER_NOTES = 'SHOPIFY_ORDER_NOTES',
  SHOPIFY_LOGISTICS_PLAN_META_FIELD = 'SHOPIFY_LOGISTICS_PLAN_META_FIELD',
}

export enum SensorChartThresholdLineOption {
  ORG_THRESHOLD_LINE = 'ORG_THRESHOLD_LINE',
  TWO_TO_EIGHT_C = 'TWO_TO_EIGHT_C',
  FIFTEEN_TO_THIRTY_C = 'FIFTEEN_TO_THIRTY_C',
  NO_THRESHOLD_LINE = 'NO_THRESHOLD_LINE',
}
