import { Button, HStack, Icon, Text } from '@chakra-ui/react'
import { useActions } from 'kea'
import { CiMoneyCheck1 } from 'react-icons/ci'

import PlanTierTag from './PlanTierTag'
import { planManagementLogic } from './planManagementLogic'

export default function OpenSaasPlanManagementButton(): JSX.Element | null {
  const { openModal } = useActions(planManagementLogic)

  return (
    // specify fontWeight because SideBar (seems to) make this bold
    <Button variant={'unstyled'} onClick={openModal} fontWeight={'normal'}>
      <HStack mt="2" borderLeftRadius="lg" pl="3" fontSize="lg" w="full">
        <Icon w="5" as={CiMoneyCheck1}></Icon>
        <Text>Manage Plan</Text>
        <PlanTierTag />
      </HStack>
    </Button>
  )
}
