import { actions, connect, events, kea, listeners, path, selectors } from 'kea'
import { loaders } from 'kea-loaders'
import posthog from 'posthog-js'

import { api } from '../Api'
import { User } from '../Types'
import { authLogic } from './authLogic'
import type { userLogicType } from './userLogicType'

export const userLogic = kea<userLogicType>([
  path(['lib', 'logics', 'userLogic', 'userLogic']),
  connect({ actions: [authLogic, ['setAuthCredentials']], values: [authLogic, ['isAuthenticated']] }),
  actions({
    loadUser: true,
  }),
  loaders(() => ({
    user: [
      null as null | User,
      {
        loadUser: async (_, breakpoint) => {
          breakpoint()
          const url = '/api/users/me/'
          const response = await api.get(url)
          return response.user
        },
      },
    ],
  })),
  listeners(({ actions }) => ({
    setAuthCredentials: () => {
      actions.loadUser()
    },
    loadUserSuccess: ({ user }) => {
      if (user) {
        posthog.identify(user.uuid, { email: user.email, organization_name: user.organization?.name })
      }
    },
  })),
  selectors({
    hasOrganization: [(selectors) => [selectors.user], (user) => !!user?.organization?.uuid],
    organization: [(selectors) => [selectors.user], (user) => user?.organization],
    organizationName: [(selectors) => [selectors.organization], (organization) => organization?.name],
    organizationPlanTier: [(selectors) => [selectors.organization], (organization) => organization?.plan_tier],
    organizationIsSubscriber: [
      (selectors) => [selectors.user],
      (user) => !['NO_PLAN', 'SENSORS_ONLY'].includes(user?.organization?.plan_tier ?? 'NO_PLAN'),
    ],
    organizationSensorDiscount: [
      (selectors) => [selectors.user],
      (user) => user?.organization?.sensor_discount_percent ?? 0,
    ],
    organizationBillingProvider: [
      (selectors) => [selectors.organization],
      (organization) => organization?.billing_provider,
    ],
    organizationUsageLimits: [(selectors) => [selectors.organization], (organization) => organization?.usage_limits],
    organizationCurrentWeekRecommendationCount: [
      (selectors) => [selectors.organization],
      (organization) => organization?.current_week_recommendation_count ?? 0,
    ],
    bulkRecommendationEnabled: [
      (selectors) => [selectors.user],
      (user) => !!user?.organization?.bulk_recommendation_enabled,
    ],
  }),
  events(({ actions, values }) => ({
    afterMount: () => {
      if (values.isAuthenticated) {
        actions.loadUser()
      }
    },
  })),
])
