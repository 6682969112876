import { createStandaloneToast } from '@chakra-ui/react'
import { dayjs } from 'lib/dayjs'

import {
  AmbientTempProfileType,
  CoolantBlocksDescription,
  CoolantDescription,
  CoolantType,
  DayOfWeek,
  InsulationType,
  KICError,
  KICWarning,
  OMSProvider,
  RecommendationPolicy,
  RecommendationTriggerPolicy,
  ShippingServiceLevel,
  ShippingServiceLevelUpgradePolicy,
  ShopifyLogisticsPlanFieldOption,
  SimulationThresholdField,
  TempReading,
} from './Types'
import { DataMapping } from './components/forms/DataMapping'

export const normalizeFormErrors = (errors: Record<string, string[]>): Record<string, string> => {
  const normalizedErrors: Record<string, string> = {}

  Object.entries(errors).forEach(([key, value]) => {
    normalizedErrors[key] = value[0]
  })

  return normalizedErrors
}

export const convertCelsiusToFahrenheit = <T extends number | undefined>(celsius: T): T => {
  return typeof celsius === 'number' ? ((celsius * 1.8 + 32) as T) : celsius
}

export const convertFahrenheitToCelsius = <T extends number | undefined>(fahrenheit: T): T => {
  return typeof fahrenheit === 'number' ? (((fahrenheit - 32) / 1.8) as T) : fahrenheit
}

export const scrollbarWidth = (): number => {
  // thanks too https://davidwalsh.name/detect-scrollbar-width
  const scrollDiv = document.createElement('div')
  scrollDiv.setAttribute('style', 'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;')
  document.body.appendChild(scrollDiv)
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
  document.body.removeChild(scrollDiv)
  return scrollbarWidth
}

export function testFalsey(val: any): boolean {
  return val === undefined || val === null || val === ''
}

export function convertRemToPixels(rem: number): number {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
}

export function roundTo<T extends number | undefined | null>(num: T, decimalPlaces = 1): T {
  return typeof num === 'number' ? (Number(num.toFixed(decimalPlaces)) as T) : num
}

export function toPercentString(float: number, decimalPlaces = 0): string {
  return `${roundTo(float * 100, decimalPlaces)}%`
}

export const coolantTypeDisplayNameMapping: Record<CoolantType, string> = {
  [CoolantType.DryIce]: 'Dry Ice',
  [CoolantType.GelPacks]: 'Gel Packs',
}
export function getCoolantDisplayName(coolantType?: string): string | undefined {
  if (!coolantType) {
    return undefined
  }
  return coolantTypeDisplayNameMapping[coolantType as CoolantType]
}

export function getInsulationDisplayName(insulationType: string): string {
  const mapping: Record<InsulationType, string> = {
    [InsulationType.StarchBased]: 'Starch Based (e.g. ClimaCell)',
    [InsulationType.Polystyrene]: 'Polystyrene (e.g. Styrofoam)',
    [InsulationType.CottonBased]: 'Cotton Based (e.g. NaturalBlue)',
    [InsulationType.ReflectiveFoil]: 'Reflective Foil (e.g. Reflectix)',
    [InsulationType.PETBased]: 'PET Based (e.g. Renewliner)',
    [InsulationType.PaperBased]: 'Paper Based (e.g. WaveKraft)',
    [InsulationType.Other]: 'Other',
  }
  return mapping[insulationType as InsulationType]
}

export const sensorStatusDisplayNameMapping: Record<string, string> = {
  SENSOR_NOT_SCANNED: '',
  SENSOR_SCANNED: 'Scanned',
}
export function getSensorStatusDisplayName(sensorStatus: string): string {
  return sensorStatusDisplayNameMapping[sensorStatus]
}

export const shipmentStatusDisplayNameMapping: Record<string, string> = {
  DELIVERED: 'Delivered',
  PENDING: 'Upcoming',
  IN_TRANSIT: 'In transit',
  SHIPPING_DATA_NOT_FETCHED: 'Fetching...',
  TRACKING_DATA_ERROR: 'Error',
  EXPIRED_BEFORE_SHIPPING: 'Expired',
  CANCELLED: 'Cancelled',
  EXCEPTION: 'Exception',
}

export function getShipmentStatusDisplayName(shipmentStatus: string): string {
  return shipmentStatusDisplayNameMapping[shipmentStatus]
}

export const carrierToDisplayNameMapping: Record<string, string> = {
  fedex: 'FedEx',
  ups: 'UPS',
  gso: 'GSO',
  laser_ship: 'LaserShip',
  on_trac: 'OnTrac',
  uds: 'UDS',
  usps: 'USPS',
}

export function getCarrierDisplayName(carrier?: string): string | undefined {
  if (!carrier) {
    return carrier
  }
  return carrierToDisplayNameMapping[carrier] || carrier
}

export const carrierServiceToDisplayNameMapping: Record<string, string> = {
  fedex_international_priority_express: 'FedEx International Priority Express',
  fedex_ground: 'FedEx Ground',
  fedex_home_delivery: 'FedEx Home Delivery',
  fedex_2day: 'FedEx 2Day',
  fedex_2day_am: 'FedEx 2Day AM',
  fedex_express_saver: 'FedEx Express Saver',
  fedex_standard_overnight: 'FedEx Standard Overnight',
  fedex_priority_overnight: 'FedEx Priority Overnight',
  fedex_first_overnight: 'FedEx First Overnight',
  fedex_1_day_freight: 'FedEx 1 Day Freight',
  fedex_2_day_freight: 'FedEx 2 Day Freight',
  fedex_3_day_freight: 'FedEx 3 Day Freight',
  fedex_first_overnight_freight: 'FedEx First Overnight Freight',
  fedex_ground_international: 'FedEx Ground International',
  fedex_international_economy: 'FedEx International Economy',
  fedex_international_priority: 'FedEx International Priority',
  fedex_international_first: 'FedEx International First',
  fedex_international_economy_freight: 'FedEx International Economy Freight',
  fedex_international_priority_freight: 'FedEx International Priority Freight',
  fedex_international_connect_plus: 'FedEx International Connect Plus',
  ups_ground: 'UPS Ground',
  ups_3_day_select: 'UPS 3 Day Select',
  ups_2nd_day_air: 'UPS 2nd Day Air',
  ups_2nd_day_air_am: 'UPS 2nd Day Air AM',
  ups_next_day_air_saver: 'UPS Next Day Air Saver',
  ups_next_day_air_early_am: 'UPS Next Day Air Early AM',
  ups_next_day_air: 'UPS Next Day Air',
  ups_standard_international: 'UPS Standard International',
  gls_priority: 'GLS Priority',
  gls_early_priority: 'GLS Early Priority',
  gls_ground: 'GLS Ground',
  gls_saturday: 'GLS Saturday',
  gls_early_saturday: 'GLS Early Saturday',
  uds_standard: 'UDS Standard',
  on_trac_ground: 'OnTrac Ground',
  on_trac_sunrise: 'OnTrac Sunrise',
  on_trac_sunrise_gold: 'OnTrac Sunrise Gold',
  usps_priority_mail: 'USPS Priority Mail',
  usps_first_class_mail: 'USPS First Class Mail',
  usps_media_mail: 'USPS Media Mail',
  usps_parcel_select: 'USPS Parcel Select',
  usps_priority_mail_express: 'USPS Priority Mail Express',
  usps_first_class_mail_international: 'USPS First Class Mail International',
  usps_priority_mail_international: 'USPS Priority Mail International',
  usps_priority_mail_express_international: 'USPS Priority Mail Express International',
}

export function getCarrierServiceDisplayName(carrier_service?: string): string | undefined {
  if (!carrier_service) {
    return carrier_service
  }
  return carrierServiceToDisplayNameMapping[carrier_service] || carrier_service
}

export interface Match {
  target: string
  rating: number
}

export function compareTwoStrings(first: string, second: string): number {
  first = first.replace(/\s+/g, '')
  second = second.replace(/\s+/g, '')

  if (first === second) return 1 // identical or empty
  if (first.length < 2 || second.length < 2) return 0 // if either is a 0-letter or 1-letter string

  const firstBigrams = new Map()
  for (let i = 0; i < first.length - 1; i++) {
    const bigram = first.substring(i, i + 2)
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) + 1 : 1

    firstBigrams.set(bigram, count)
  }

  let intersectionSize = 0
  for (let i = 0; i < second.length - 1; i++) {
    const bigram = second.substring(i, i + 2)
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) : 0

    if (count > 0) {
      firstBigrams.set(bigram, count - 1)
      intersectionSize++
    }
  }

  return (2.0 * intersectionSize) / (first.length + second.length - 2)
}

export function findBestStringMatch(
  mainString: string,
  targetStrings: string[],
  ignoreStringCase = true
): { ratings: Match[]; bestMatch: Match; bestMatchIndex: number } {
  const ratings = []
  let bestMatchIndex = 0

  for (let i = 0; i < targetStrings.length; i++) {
    const currentTargetString = targetStrings[i]
    const currentRating = ignoreStringCase
      ? compareTwoStrings(mainString.toUpperCase(), currentTargetString.toUpperCase())
      : compareTwoStrings(mainString, currentTargetString)
    ratings.push({ target: currentTargetString, rating: currentRating })
    if (currentRating > ratings[bestMatchIndex].rating) {
      bestMatchIndex = i
    }
  }

  const bestMatch = ratings[bestMatchIndex]

  return { ratings: ratings, bestMatch: bestMatch, bestMatchIndex: bestMatchIndex }
}

export function isNumeric(value: string): boolean {
  return /^-?\d+$/.test(value)
}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function identifierToHuman(identifier: string | number, caseType: 'sentence' | 'title' = 'sentence'): string {
  const words: string[] = []
  let currentWord = ''
  String(identifier)
    .trim()
    .split('')
    .forEach((character) => {
      if (character === '_' || character === '-') {
        if (currentWord) {
          words.push(currentWord)
        }
        currentWord = ''
      } else if (
        character === character.toLowerCase() &&
        (!'0123456789'.includes(character) ||
          (currentWord && '0123456789'.includes(currentWord[currentWord.length - 1])))
      ) {
        currentWord += character
      } else {
        if (currentWord) {
          words.push(currentWord)
        }
        currentWord = character.toLowerCase()
      }
    })
  if (currentWord) {
    words.push(currentWord)
  }
  return capitalizeFirstLetter(
    words.map((word) => (caseType === 'sentence' ? word : capitalizeFirstLetter(word))).join(' ')
  )
}

export function getKeyByValue(object: Record<string, any>, value: any): string | undefined {
  return Object.keys(object).find((key) => object[key] === value)
}

export function getStateAbbrevMapping(): Record<string, string> {
  return {
    AL: 'AL',
    AK: 'AK',
    AZ: 'AZ',
    AR: 'AR',
    CA: 'CA',
    CO: 'CO',
    CT: 'CT',
    DE: 'DE',
    DC: 'DC',
    FL: 'FL',
    GA: 'GA',
    HI: 'HI',
    ID: 'ID',
    IL: 'IL',
    IN: 'IN',
    IA: 'IA',
    KS: 'KS',
    KY: 'KY',
    LA: 'LA',
    ME: 'ME',
    MD: 'MD',
    MA: 'MA',
    MI: 'MI',
    MN: 'MN',
    MS: 'MS',
    MO: 'MO',
    MT: 'MT',
    NB: 'NB',
    NV: 'NV',
    NH: 'NH',
    NJ: 'NJ',
    NM: 'NM',
    NY: 'NY',
    NC: 'NC',
    ND: 'ND',
    OH: 'OH',
    OK: 'OK',
    OR: 'OR',
    PA: 'PA',
    PR: 'PR',
    RI: 'RI',
    SC: 'SC',
    SD: 'SD',
    TN: 'TN',
    TX: 'TX',
    UT: 'UT',
    VT: 'VT',
    VA: 'VA',
    WA: 'WA',
    WV: 'WV',
    WI: 'WI',
    WY: 'WY',
  }
}

export const convertHourNumberToString = (hour: number): string => {
  return hour === 0 ? '12 AM' : hour < 12 ? `${hour} AM` : hour === 12 ? '12 PM' : `${hour - 12} PM`
}

export const cleanAndCapitalizeString = (str: string): string => {
  return str.trim().replace(/^\w/, (c) => c.toUpperCase())
}

export const truncateString = (str: string, maxLength: number): string => {
  return str.length > maxLength ? str.substring(0, maxLength - 3) + '...' : str
}

export const getDisplayTextForRecommendationTriggerPolicy = (policy: RecommendationTriggerPolicy): string => {
  const mapping = {
    [RecommendationTriggerPolicy.ON_SHIPMENT_CREATION]: 'When a shipment is created',
    [RecommendationTriggerPolicy.ON_SHIPMENT_CREATION_OR_UPDATE]: 'When a shipment is created or edited',
    [RecommendationTriggerPolicy.ON_EVERY_SYNC]: 'On every sync',
    [RecommendationTriggerPolicy.ON_PREDICTION_EXPIRATION_OR_SHIPMENT_CREATION_OR_UPDATE]: 'Daily',
    [RecommendationTriggerPolicy.NO_SYNC]: 'Never',
  }
  return mapping[policy]
}

export const getDisplayTextForRecommendationPolicy = (policy: RecommendationPolicy): string => {
  const mapping = {
    [RecommendationPolicy.ALWAYS]: 'Always',
    [RecommendationPolicy.NEVER]: 'Never (always use your value)',
    [RecommendationPolicy.WHEN_BLANK]: 'If the field is blank',
  }
  return mapping[policy]
}

export const getDefaultDescriptionForCoolantLbsByType = (
  coolantBlocksDescription?: CoolantBlocksDescription
): string => {
  if (!coolantBlocksDescription) {
    return ''
  }
  return `${coolantBlocksDescription.coolant_block_count} x ${
    coolantBlocksDescription.coolant_block_weight_lbs
  } lbs ${getCoolantDisplayName(coolantBlocksDescription.coolant_block_type)}`
}

export const convertCoolantLbsByTypeToCoolantDescriptionList = (
  coolantLbsByType?: Partial<Record<CoolantType, number>>
): CoolantDescription[] => {
  if (!coolantLbsByType) {
    return []
  }
  return Object.keys(coolantLbsByType)
    ?.map((coolantType) => {
      return coolantLbsByType[coolantType as CoolantType]
        ? { coolant_type: coolantType as CoolantType, coolant_lbs: coolantLbsByType[coolantType as CoolantType] }
        : null
    })
    .filter((coolantDescription) => coolantDescription !== null) as CoolantDescription[]
}

export const convertCoolantDescriptionListToCoolantLbsByType = (
  coolantDescriptionList?: CoolantDescription[]
): Partial<Record<CoolantType, number>> => {
  if (!coolantDescriptionList) {
    return {}
  }
  return coolantDescriptionList.reduce((acc, coolantDescription) => {
    if (coolantDescription.coolant_type && coolantDescription.coolant_lbs) {
      const existingValue = acc[coolantDescription.coolant_type]
      if (existingValue) {
        acc[coolantDescription.coolant_type] = coolantDescription.coolant_lbs + existingValue
      } else {
        acc[coolantDescription.coolant_type] = coolantDescription.coolant_lbs
      }
    }
    return acc
  }, {} as Partial<Record<CoolantType, number>>)
}

export const simulationThresholdFieldDisplayNameMapping: Record<SimulationThresholdField, string> = {
  [SimulationThresholdField.Product]: 'Product temperature',
  [SimulationThresholdField.ProductSurface]: 'Product surface temperature',
  [SimulationThresholdField.ProductCore]: 'Product core temperature',
  [SimulationThresholdField.Coolant]: 'Coolant temperature',
  [SimulationThresholdField.CoolantSurface]: 'Coolant surface temperature',
  [SimulationThresholdField.CoolantCore]: 'Coolant core temperature',
}

export const getSimulationThresholdFieldDisplayName = (thresholdField: SimulationThresholdField): string => {
  return simulationThresholdFieldDisplayNameMapping[thresholdField]
}

export function objectClean<T extends Record<string | number | symbol, unknown>>(obj: T): T {
  const response = { ...obj }
  Object.keys(response).forEach((key) => {
    if (response[key] === undefined) {
      delete response[key]
    }
  })
  return response
}

export const ambientProfileTypeToDisplayNameMapping: Record<AmbientTempProfileType, string> = {
  [AmbientTempProfileType.MODERATE_DAY_NIGHT_CYCLE]: 'Standard',
  [AmbientTempProfileType.HOT_TO_COLD_CLIMATE]: 'Hot to cold',
  [AmbientTempProfileType.COLD_TO_HOT_CLIMATE]: 'Cold to hot',
  [AmbientTempProfileType.SIGNIFICANT_DAY_NIGHT_CYCLE]: 'Significant day night cycle',
  [AmbientTempProfileType.MINIMAL_DAY_NIGHT_CYCLE]: 'Minimal day night cycle',
}

export const getAmbientProfileTypeDisplayName = (profileType: AmbientTempProfileType): string => {
  return ambientProfileTypeToDisplayNameMapping[profileType]
}

export const convertHourToDisplayString = (hour: number): string => {
  return hour === 0 ? '12 AM' : hour < 12 ? `${hour} AM` : hour === 12 ? '12 PM' : `${hour - 12} PM`
}

export const getShortDayStringFromDayOfWeek = (dayOfWeek: DayOfWeek): string => {
  return capitalizeFirstLetter(dayOfWeek.slice(0, 3).toLocaleLowerCase())
}

export const shippingServiceLevelToDisplayNameMapping: Record<string, string> = {
  [ShippingServiceLevel.STANDARD]: 'Standard',
  [ShippingServiceLevel.EXPEDITED]: 'Expedited',
  [ShippingServiceLevel.OVERNIGHT]: 'Overnight',
}

export const getShippingServiceLevelDisplayName = (serviceLevel?: ShippingServiceLevel): string | undefined => {
  if (!serviceLevel) {
    return serviceLevel
  }
  return shippingServiceLevelToDisplayNameMapping[serviceLevel]
}

export const serviceLevelUpgradePolicyToDisplayNameMapping: Record<ShippingServiceLevelUpgradePolicy, string> = {
  [ShippingServiceLevelUpgradePolicy.NO_UPGRADES]: "Don't upgrade",
  [ShippingServiceLevelUpgradePolicy.ALLOW_SINGLE_LEVEL_UPGRADES]:
    'Allow single level upgrades (e.g. Standard to Expedited)',
  [ShippingServiceLevelUpgradePolicy.ALLOW_UPGRADES]: 'Allow multiple level upgrades (e.g. Standard to Overnight)',
}

export const getServiceLevelUpgradePolicyDisplayName = (
  serviceLevelUpgradePolicy?: ShippingServiceLevelUpgradePolicy
): string | undefined => {
  if (!serviceLevelUpgradePolicy) {
    return serviceLevelUpgradePolicy
  }
  return serviceLevelUpgradePolicyToDisplayNameMapping[serviceLevelUpgradePolicy]
}

export const checkForZipCodePatternErrorMessage = (zipCodeListString: string | null): string | undefined => {
  if (zipCodeListString && !zipCodeListString.match(/^[0-9*]{5}(,[0-9*]{5})*$/)) {
    return 'Zip code list must be a comma separated list of 5 digit zip codes. "*" is allowed as a wildcard.'
  }
  return undefined
}

export function inflect(quantity: number, word: string, decimalPlaces = 0): string {
  return `${quantity.toFixed(decimalPlaces)} ${word}${quantity === 1 ? '' : 's'}`
}

export function alphabetize<T extends Record<string, any>>(
  items: T[],
  key: (item: Record<string, any>) => string = (item) => item.name
): T[] {
  return items.sort((a, b) => key(a).localeCompare(key(b)))
}

export function valueRequired(valueOrCondition: boolean | any, errorMessage: string): string | undefined {
  if (typeof valueOrCondition === 'number') {
    if (!valueOrCondition && valueOrCondition !== 0) {
      return errorMessage
    }
  } else {
    return valueOrCondition ? undefined : errorMessage
  }
}

export function mustBeGreaterThanZero(value: number | undefined, errorMessage: string): string | undefined {
  return value && value > 0 ? undefined : errorMessage
}

export const { toast } = createStandaloneToast()

const debouncedToasts: Record<string, number> = {}

export function toastKey(
  title: string,
  status: 'info' | 'warning' | 'success' | 'error',
  description?: string
): string {
  return `${title}-${status}-${description}`
}

export function toastWithDebounce(
  title: string,
  status: 'info' | 'warning' | 'success' | 'error',
  description: string | undefined = undefined,
  duration = 5000,
  debounce = 500
): void {
  if (debouncedToasts[title]) {
    clearTimeout(debouncedToasts[toastKey(title, status, description)])
  }
  debouncedToasts[toastKey(title, status, description)] = window.setTimeout(() => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: duration,
      position: 'bottom-right',
      isClosable: true,
    })
  }, debounce)
}

export function clearToastWithDebounce(
  title: string,
  status: 'info' | 'warning' | 'success' | 'error',
  description?: string
): void {
  if (debouncedToasts[toastKey(title, status, description)]) {
    clearTimeout(debouncedToasts[toastKey(title, status, description)])
    delete debouncedToasts[toastKey(title, status, description)]
  }
}

export async function toastOnCompletionAsync<T>(
  fn: () => Promise<T>,
  errorToastProps: { title: string; description?: string },
  successToastProps: { title: string; description?: string }
): Promise<T> {
  try {
    const response = await fn()
    toast({
      ...successToastProps,
      status: 'success',
      duration: 5000,
      position: 'bottom-right',
      isClosable: true,
    })

    return response
  } catch (e) {
    toast({
      ...errorToastProps,
      status: 'error',
      duration: 5000,
      position: 'bottom-right',
      isClosable: true,
    })
    throw e
  }
}

export const getHumanReadableWarning = (warning: KICWarning): string => {
  const mapping: Record<KICWarning, string> = {
    [KICWarning.TRANSIT_TIME_LONGER_DUE_TO_WEEKEND_OR_HOLIDAY]:
      'The transit time is longer due to a weekend or holiday',
    [KICWarning.SHIPPING_SERVICE_LEVEL_UPGRADED]: 'The shipping service level was upgraded',
    [KICWarning.TEMP_THRESHOLD_EXCEEDED]:
      'The temperature threshold was exceeded in the simulation and we could not find a different way to keep the product within the threshold.',
    [KICWarning.BOX_WEIGHT_LIMIT_EXCEEDED]:
      'The box weight limit was exceeded by the combined weight of the product and coolant.',
    [KICWarning.ITEM_MISSING_WEIGHT_SO_FALLING_BACK_TO_DEFAULT]: 'Item missing weight so falling back to default',
  }
  return mapping[warning] || warning
}

export const getHumanReadableError = (error: KICError): string => {
  const mapping: Record<KICError, string> = {
    [KICError.NO_COOLANT_OPTIONS]:
      'No coolant options found. Make sure coolant is configured for all possible packaging configurations.',
    [KICError.NO_SHIPPING_METHOD_OPTIONS]:
      'No matching shipping method options were found. Make sure shipping methods are configured that match the given service levels, ship dates, destinations or distribution centers.',
    [KICError.NO_PACKAGING_CONFIG_OPTIONS]:
      'No packaging config options found. Make sure packaging configs are configured for all possible product types.',
    [KICError.NO_SHIP_DATE_OPTIONS]: 'No possible ship dates were found. Please make sure ship dates are not limited.',
    [KICError.NO_SHIPPING_SERVICE_LEVEL_OPTIONS]: 'No shipping service level options found.',
    [KICError.WEATHER_FORECAST_IS_ONLY_AVAILABLE_14_DAYS_INTO_FUTURE]:
      'All possible recommendations are delivered more than 14 days in the future (the limits of our weather forecasts).',
    [KICError.DISTRIBUTION_CENTER_DATA_MAPPING_MISSING]:
      'Distribution center mapping is missing. Make sure it is set in your sync settings.',
    [KICError.SHIPPING_METHOD_DATA_MAPPING_MISSING]:
      'Shipping method mapping is missing. Make sure it is set in your sync settings.',
    [KICError.PACKAGING_CONFIG_DATA_MAPPING_MISSING]:
      'Packaging config data mapping is missing. Make sure it is set in your sync settings.',
    [KICError.SHIPPING_SERVICE_LEVEL_DATA_MAPPING_MISSING]:
      'The shipping service level data mapping is missing. Make sure it is set in your sync settings.',
    [KICError.CARRIER_SERVICE_DATA_MAPPING_MISSING]:
      'The carrier service data mapping is missing. Make sure it is set in your sync settings.',
    [KICError.CARRIER_DATA_MAPPING_MISSING]:
      'The carrier data mapping is missing. Make sure the carrier on the order matches a carrier in your sync settings.',
    [KICError.DESTINATION_ADDRESS_NOT_VALID]:
      'The destination address is not valid. Make sure it is a valid US address.',
  }
  return mapping[error] || error
}

function fahrenheitToKelvin(tempF: number): number {
  return ((tempF - 32) * 5) / 9 + 273.15
}

const GAS_CONSTANT_J_PER_MOL_K = 8.314462618 as const
const DEFAULT_ACTIVATION_ENERGY = 80 as const
export function meanKineticTemperatureC(
  readings: TempReading[],
  activationEnergy: number = DEFAULT_ACTIVATION_ENERGY, // aso expressed as ΔH
  gasConstantJPerMokJ: number = GAS_CONSTANT_J_PER_MOL_K
): number {
  if (readings.length < 2) {
    console.error('At least two readings are required to calculate the mean kinetic temperature')
    return NaN
  }

  let numerator = 0
  let denominator = 0

  for (let i = 0; i < readings.length - 1; i++) {
    const currentReading = readings[i]
    const nextReading = readings[i + 1]

    if (currentReading.internal_temp_f !== undefined && nextReading.internal_temp_f !== undefined) {
      const tempK = fahrenheitToKelvin(currentReading.internal_temp_f)
      const nextTempK = fahrenheitToKelvin(nextReading.internal_temp_f)

      // Calculate the time interval in hours between the two readings
      const currentTime = dayjs(currentReading.time)
      const nextTime = dayjs(nextReading.time)
      const hoursInTransit = nextTime.diff(currentTime, 'hour', true) // Using true for fractional hours

      // Update the numerator and denominator for each interval
      const exponentCurrent = -activationEnergy / (gasConstantJPerMokJ * tempK)
      const exponentNext = -activationEnergy / (gasConstantJPerMokJ * nextTempK)

      numerator += (hoursInTransit * (Math.exp(exponentCurrent) + Math.exp(exponentNext))) / 2
      denominator += hoursInTransit
    }
  }

  if (denominator === 0) {
    console.error('The denominator is zero, which would result in a division by zero')
    return NaN
  }

  const lnValue = Math.log(numerator / denominator)
  const mkt = -activationEnergy / gasConstantJPerMokJ / lnValue

  return mkt - 273.15
}

export const mergeDataMappings = (
  existingDataMappings: DataMapping[],
  incomingDataMappings: DataMapping[],
  mergeOnSide: 'internal_value' | 'external_value' = 'internal_value',
  innerOrOuterJoin: 'inner' | 'outer' = 'inner'
): DataMapping[] => {
  const mergedDataMappings = [...existingDataMappings]
  incomingDataMappings.forEach((incomingDataMapping) => {
    const existingDataMappingIndex = mergedDataMappings.findIndex(
      (existingDataMapping) =>
        JSON.stringify(existingDataMapping[mergeOnSide]) === JSON.stringify(incomingDataMapping[mergeOnSide])
    )
    if (existingDataMappingIndex === -1 && innerOrOuterJoin === 'outer') {
      mergedDataMappings.push(incomingDataMapping)
    } else {
      mergedDataMappings[existingDataMappingIndex] = incomingDataMapping
    }
  })
  return mergedDataMappings
}

export const isIndexUniqueInList = (index: number, valueList: any[]): boolean => {
  const countSame = valueList.filter((compareTo) => {
    return JSON.stringify(valueList[index]) === JSON.stringify(compareTo)
  }).length
  return countSame <= 1
}

export function range(start: number, end: number): number[] {
  return Array.from({ length: end - start + 1 }, (_, i) => start + i)
}

export const convertListOfStringToSentence = (list: string[]): string => {
  if (list.length === 0) return ''
  if (list.length === 1) return list[0]
  if (list.length === 2) return list.join(' and ')
  return `${list.slice(0, -1).join(', ')}, and ${list[list.length - 1]}`
}

export const getHumanReadableShopifyLogisticsPlanFieldOption = (option: ShopifyLogisticsPlanFieldOption): string => {
  const mapping: Record<ShopifyLogisticsPlanFieldOption, string> = {
    [ShopifyLogisticsPlanFieldOption.SHOPIFY_LOGISTICS_PLAN_META_FIELD]: 'Order logistics plan meta field',
    [ShopifyLogisticsPlanFieldOption.SHOPIFY_ORDER_NOTES]: 'Order note',
  }
  return mapping[option] || option
}

export const getHumanReadableOMSIntegration = (provider: OMSProvider): string => {
  const mapping: Record<OMSProvider, string> = {
    [OMSProvider.ShipStation]: 'ShipStation',
    [OMSProvider.Shopify]: 'Shopify',
  }
  return mapping[provider] || provider
}
